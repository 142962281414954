import { useCallback, useEffect, useState, React } from 'react'
import { solidityCompiler } from '@agnostico/browser-solidity-compiler'
import { ClipLoader } from 'react-spinners'
import { useContractCode } from './hooks/useContractCode'
import { ethers } from 'ethers'
import chains from './chains.json'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { BiCopy } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const provider = window.ethereum
  ? new ethers.BrowserProvider(window.ethereum)
  : undefined

export default function App() {
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')
  const [supply, setSupply] = useState('')
  const [decimals, setDecimals] = useState('18')
  const [signer, setSigner] = useState()
  const [address, setAddress] = useState()
  const [deployedContractAddress, setDeployedContractAddress] = useState()
  const [deployedContractCode, setDeployedContractCode] = useState()
  const [deployedContractAbi, setDeployedContractAbi] = useState()
  const [isConnected, setIsConnected] = useState(false)
  const [isDeploying, setIsDeploying] = useState(false)
  const [isDeployed, setIsDeployed] = useState(false)

  const { contractCode } = useContractCode({ name, symbol, supply })

  const handleConnect = useCallback(async () => {
    if (!provider) {
      return toast.error('Cannot detect a wallet to connect with.')
    }
    try {
      const _signer = await provider.getSigner()
      const _address = await provider.send('eth_requestAccounts', [])
      if (!_signer || !_address) {
        return
      }
      setSigner(_signer)
      setAddress(_address)
      setIsConnected(true)
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        return toast.error('User denied connection request.')
      }
      return toast.error('An error occurred. Please try again.')
    }
  }, [])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      setIsDeploying(true)
      console.log('Compiling')
      await solidityCompiler({
        version: `https://binaries.soliditylang.org/bin/soljson-v0.8.19+commit.7dd6d404.js`,
        contractBody: contractCode,
      })
        .then(async (compile) => {
          console.log('Compiled')
          const abi = compile.contracts.Compiled_Contracts.Token.abi
          const bytecode =
            compile.contracts.Compiled_Contracts.Token.evm.bytecode.object.trim()
          const factory = new ethers.ContractFactory(abi, bytecode, signer)
          console.log('Deploying')
          await factory
            .deploy()
            .then(async (r) => {
              console.log('Deployed')
              const address = await r.getAddress()
              setIsDeploying(false)
              setIsDeployed(true)
              setDeployedContractAddress(address)
              setDeployedContractCode(contractCode)
              setDeployedContractAbi(JSON.stringify(abi))
            })
            .catch((e) => {
              window.location.reload()
            })
        })
        .catch((e) => console.log(e))
    },
    [contractCode, signer],
  )

  const handleCopy = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>ERC20 Token Generator | Minting.tools</title>

        {/* Standard Meta */}
        <meta name="title" content="ERC20 Token Generator | Minting.tools" />
        <meta
          name="description"
          content="Generate and deploy your own ERC20 token on any EVM compatible blockchain. For free. No coding required."
        />
        <meta
          name="keywords"
          content="erc20 token, create erc20 token, crypto generator, create crypto token, erc20 token generator, bep20 token generator, create your own crypto token free, crypto token generator, erc20 token creator"
        />

        {/* OG */}
        <meta
          property="og:title"
          content="ERC20 Token Generator | Minting.tools"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://minting.tools" />
        <meta property="og:image" content="https://minting.tools/favicon.png" />

        <link rel="shortcut icon" href="/favicon.png" type="image/x-icon" />
      </Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-X6GZ9QXGZT"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-X6GZ9QXGZT");
        `,
        }}
      />
      <header className="navbar bg-light">
        <div className="container">
          <a href="https://rpc.info">RPC Info</a>
          <a href="https://chain.compare">Chain Compare</a>
          <a href="https://cryptowallets.gg">Crypto Wallets</a>
          <a href="https://exchanges.gg">Crypto Exchanges</a>
        </div>
      </header>
      <main className="container mt-3">
        <div className="row">
          <section className="col-md-6">
            <header>
              <h1 className="fw-bolder">ERC20 Token Generator 🪙</h1>
              <p>Generate your own ERC20 token for free, in seconds!</p>
            </header>
            <section>
              {address ? (
                <Connected />
              ) : (
                <button
                  className="btn btn-primary mb-3"
                  style={{ width: 300 }}
                  onClick={handleConnect}
                  disabled={isConnected}
                >
                  Connect
                </button>
              )}
            </section>
            {!isDeployed && (
              <>
                <h2>Builder</h2>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <label htmlFor="name">
                    Name
                    <Required />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={name}
                    disabled={!isConnected}
                    onChange={(e) => setName(e.target.value.trim())}
                  />
                  <label htmlFor="symbol">
                    Symbol
                    <Required />
                  </label>
                  <input
                    type="text"
                    disabled={!isConnected}
                    className="form-control"
                    name="symbol"
                    required
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value.trim())}
                  />
                  <label htmlFor="supply">
                    Supply
                    <Required />{' '}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={supply}
                    onChange={(e) => setSupply(e.target.value.trim())}
                    disabled={!isConnected}
                  />
                  <label htmlFor="decimals">
                    Decimals
                    <Required />
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="decimals"
                    value={decimals}
                    disabled={true}
                    onChange={(e) => setDecimals(e.target.value)}
                  />

                  {isDeploying ? (
                    <center className="my-3">
                      <ClipLoader loading color="#0d6efd" />
                    </center>
                  ) : (
                    <button
                      className="btn btn-primary my-3"
                      style={{ width: 300, alignContent: 'middle' }}
                      disabled={
                        !address || !supply || !name || !symbol || !decimals
                      }
                    >
                      Create Cryptocurrency
                    </button>
                  )}
                </form>
              </>
            )}
            {isDeployed && (
              <>
                <div className="alert alert-success">
                  <strong>Contract deployed at</strong>:{' '}
                  <pre>{deployedContractAddress}</pre>
                  <div className="row">
                    <div className="col-md-6 d-flex flex-column">
                      <strong>Flattened Contract Code</strong>
                      <textarea className="form-control" disabled>
                        {deployedContractCode}
                      </textarea>
                      <button
                        className="btn btn-sm btn-light"
                        onClick={() => handleCopy(deployedContractCode)}
                      >
                        <BiCopy /> Copy
                      </button>
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                      <strong>ABI</strong>
                      <textarea className="form-control" disabled>
                        {deployedContractAbi}
                      </textarea>
                      <button
                        className="btn btn-sm btn-light"
                        onClick={() => handleCopy(deployedContractAbi)}
                      >
                        <BiCopy /> Copy
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  style={{ width: 300 }}
                  onClick={() => {
                    if (
                      window.confirm("Are you sure? You'll lose everything.")
                    ) {
                      window.location.reload()
                    }
                  }}
                >
                  Reset
                </button>
              </>
            )}
          </section>
          <section className="col-md-6 d-flex flex-column gap-1">
            <h2>About Minting Tools</h2>
            <p>
              Minting Tools is a simple app that generates ERC20 token smart
              contracts using{' '}
              <a href="https://docs.openzeppelin.com/contracts/4.x/erc20">
                OpenZeppelin contracts
              </a>
              .
            </p>
            <p>
              It provides a simple, no-code, form for you to generate ERC20
              tokens on any chain. All within a few seconds.
            </p>
            <p>
              The ERC20 tokens have a <strong>fixed supply</strong>, and cannot
              mint further tokens.
            </p>
            <h2>Show Your Support</h2>
            <p>Like Minting Tools? Please support!</p>
            <a href="https://commerce.coinbase.com/checkout/43fda207-2078-46b5-8ebc-f07bab2bc7c6">
              <button className="btn btn-primary">
                Let&apos;s Goooooo! 🚀
              </button>
            </a>
            <h2>Videos & Updates</h2>
            <p>
              Follow along with Minting Tools&apos; development progress,
              updates, and more!
            </p>
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/videoseries?si=bbr-ohX27yEwjNSX&amp;list=PLh7HUdb2p8NIFWRjou_qPakLVIuH-r7eO"
                title="YouTube video player"
                width={1080}
                height={720}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                className="embed-responsive-item"
                allowFullScreen
              ></iframe>
            </div>
            <h2>Using Minting Tools to Create an ERC20 Token</h2>
            <p>Most of the configuration is handled within your wallet.</p>
            <ol>
              <li>
                <strong>Install a browser wallet</strong>, such as{' '}
                <a href="https://metamask.io">Metamask</a>. You can find other
                Web3-enabled wallets on{' '}
                <a href="https://cryptowallets.gg">Crypto Wallets</a>.
              </li>
              <li>
                <strong>Load up your wallet with funds</strong>. If you&apos;re
                deploying on a testnet, you can get testnet gas from Testnet
                Faucets.
              </li>
              <li>
                Click the <strong>Connect</strong> button. Make sure to approve
                in your wallet.
              </li>
              <li>
                <strong>Select the chain</strong> you&apos;d like to use{' '}
                <em>in your wallet</em>. If you don&apos;t have the chain in
                your wallet, add it via <a href="https://rpc.info">RPC Info</a>.
              </li>
              <li>
                <strong>Complete</strong> the builder form.
              </li>
              <li>
                <strong>Click</strong> Create Cryptocurrency.
              </li>
              <li>
                <strong>Approve</strong> the Contract Deployment in your wallet
                and wait for the transaction to complete.
              </li>
              <li>
                <strong>Verify your contract</strong> on the relevant blockchain
                explorer by using the <em>flattened contract code</em> and{' '}
                <em>ABI</em> provided after a successful deploy. Etherscan forks
                will ask for details like optimization runs, compiler types and
                versions. We use <em>no optimization runs</em>, compiler type is{' '}
                <em>Solidity (Single File)</em>, compiler version is{' '}
                <em>0.8.19</em>.
              </li>
            </ol>
          </section>
        </div>
        <ToastContainer />
      </main>
      <footer className="text-center mt-auto bg-light border-top py-3">
        &copy; <a href="https://truemiller.com">TRUEMILLER</a> 2023-
        {new Date().getFullYear()}
      </footer>
    </HelmetProvider>
  )
}

const Required = () => {
  return <sup className="text-danger">*</sup>
}

const Connected = () => {
  const [chainName, setChainName] = useState(
    chains.find(
      (chain) => chain.networkId === parseInt(window.ethereum?.networkVersion),
    ).name,
  )
  const [address, setAddress] = useState('')
  useEffect(() => {
    const setAddressAsync = async () => {
      setAddress(await provider.send('eth_requestAccounts'), [])
    }

    setAddressAsync()
  }, [])
  useEffect(() => {
    window.ethereum.on('chainChanged', (e) => {
      setChainName(chains.find((chain) => chain.networkId === parseInt(e)).name)
    })
    window.ethereum.on('accountsChanged', (e) => {
      setAddress(e)
    })
  }, [])
  return (
    <span className="d-flex flex-column my-3">
      <span className="d-block">
        <strong>Connected to</strong>: {address}
      </span>
      <span className="d-block">
        <strong>Chain</strong>: {chainName}
      </span>
    </span>
  )
}
